import { BounceLoader } from "react-spinners";
import { Box } from "grommet";

const Loader = props => {
  const size = props.size || 60;
  return (
    <Box
      fill
      alignContent="center"
      alignSelf="center"
      align={props.align || "center"}
      justify="center"
    >
      <BounceLoader
        sizeUnit={"px"}
        size={size}
        color={"#00ebf4"}
        // loading={this.state.loading}
      />
    </Box>
  );
};

export default Loader;
